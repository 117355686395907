<template>
  <UiPopup
    :model-value="modelValue"
    title="User can’t be deleted"
    description="This user cannot be deleted as he is the only one in  active rotation group. First, change the rotation group, and then try to delete the user again."
    primary-button-text="Open rotations"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="$emit('input'), emits('update:modelValue', false)"
  >
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})
</script>

<style scoped></style>
